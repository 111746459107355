import React from "react";
//import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";

import "./i18next";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { applyMiddleware } from "redux";
import { legacy_createStore as createStore } from "redux";
import { thunk } from "redux-thunk";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import reducers from "./reducers";
import "./index.css";
import { registerLicense } from "@syncfusion/ej2-base";

const store = createStore(reducers, applyMiddleware(thunk));

// Registering Syncfusion license key
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWH5cdnRRR2ZYWUJ0X0I="
);
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </>
);

serviceWorker.unregister();
