import React, { useEffect, useState } from "react";
import { Grid, Skeleton, TextField } from "@mui/material";
import TextLoader from "../../../../Loaders/TextLoader";
import InputLabel from "../Components/InputLabel";
import { externlDateFormatAccordingToSettings } from "../../../formatGridNData/dateFormat";
import "./Widget.css";

export default function DateExtWidget(props) {
  const [attr, setAttr] = useState();
  const [loader, setLoader] = useState(true);
  const [objectLoader, setObjectLoader] = useState(true);
  const [dataItem, setDataItem] = useState("");
  const [dataItemFlag, setDataItemFlag] = useState();

  useEffect(() => {
    setLoader(true);
  }, []);

  useEffect(() => {
    if (props.attr) {
      setAttr(props.attr);
      setLoader(false);
    }
  }, [props.attr]);

  useEffect(() => {
    if (props.attr && props.attr.internalName) {
      if (props.facts[props.attr.internalName]) {
        setDataItem(props.facts[props.attr.internalName]);
        setDataItemFlag(true);
      } else {
        setObjectLoader(false);
      }
    }
  }, [props.facts]);

  useEffect(() => {
    if (dataItemFlag) {
      setObjectLoader(false);
    }
  }, [dataItemFlag]);

  return (
    <Grid item xs>
      {loader ? (
        <Skeleton variant="text" />
      ) : attr && attr.invisibility ? null : (
        <>
          <InputLabel attr={attr} objectType={props.objectType} />

          <div className="root">
            {objectLoader ? (
              <div className="loaderStyle parahSpace">
                <TextLoader />
              </div>
            ) : (
              <div className="parahSpace">
                {dataItem ? externlDateFormatAccordingToSettings(dataItem) : ""}
              </div>
            )}
          </div>
        </>
      )}
    </Grid>
  );
}
