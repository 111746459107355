function removeTags(str) {
  if (str === null || str === "") return false;
  else str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, "");
}

export default function FormatExternalGridData(data, attributes) {
  attributes?.map((typeContain) => {
    if (typeContain !== undefined && typeContain !== null && typeContain) {
      let typeContainerName = typeContain.internalName;
      let typeContainerKey = typeContain.dataType.key;

      data?.map((attribute) => {
        if (attribute && typeContainerName && attribute[typeContainerName]) {
          if (typeContainerKey === "DATE") {
            if (
              attribute[typeContainerName] &&
              attribute[typeContainerName] != null
            ) {
              attribute[typeContainerName] = new Date(
                attribute[typeContainerName]
              );
              return attribute;
            }
          } else if (typeContainerKey === "DATETIME") {
            if (
              attribute[typeContainerName] &&
              attribute[typeContainerName] != null
            ) {
              attribute[typeContainerName] = new Date(
                attribute[typeContainerName]
              );
              return attribute;
            }
          } else if (typeContainerKey === "FILE") {
            if (
              attribute[typeContainerName] !== undefined &&
              attribute[typeContainerName] != null &&
              attribute[typeContainerName]
            ) {
              if (
                attribute[typeContainerName].value !== undefined &&
                attribute[typeContainerName].value !== null &&
                attribute[typeContainerName].value != "" &&
                attribute[typeContainerName].value &&
                attribute[typeContainerName].value.length > 0
              ) {
                let namesArr = attribute[typeContainerName].value.map(
                  (attr) => {
                    return attr.filename;
                  }
                );
                attribute[typeContainerName] = namesArr.toString();
              } else {
                attribute[typeContainerName] = "";
              }
              return attribute;
            }
          } else if (typeContainerKey === "LONG_TEXT") {
            let finalData = removeTags(attribute[typeContainerName]).substring(
              0,
              50
            );
            return finalData;
          } else {
            return attribute[typeContainerName];
          }
        } else {
          return attribute[typeContainerName];
        }
      });
    }
  });
  return data;
}
